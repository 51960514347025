<template>
  <div class="start-step">
    <div class="container">
      <div class="section-header">
        <h2 class="section-header__title">
          How do you want to <span>build</span> your resume?
        </h2>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-6 col-xl-3">
          <div
            class="build-type"
            @mouseover="card1 = true"
            @mouseleave="card1 = false"
            :class="{ 'build-type__hover': card1 }"
            @click="$emit('next')"
          >
            <h3 class="build-type__title">Create from scratch</h3>
            <p class="build-type__text">
              We'll go through each section together
            </p>
          </div>
        </div>

        <div class="col-md-6 col-xl-3">
          <div
            class="build-type"
            @mouseover="card2 = true"
            @mouseleave="card2 = false"
            :class="{ 'build-type__hover': card2 }"
            @click="$emit('openUploadModal')"
          >
            <h3 class="build-type__title">I already have a Resume</h3>
            <p class="build-type__text">
              We'll transfer everything to your new template
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepInitBuild",
  data() {
    return {
      card1: false,
      card2: false,
    };
  },
  mounted() {
    document.body.scrollTop = 0; // for safari
    document.documentElement.scrollTop = 0; // for chrome, firefox, IE
  },
};
</script>

<style lang="scss" scoped>
.build-type {
  padding: 32px;
  background-color: #fff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 16px;

  @media (min-width: map_get($grid-breakpoints, md)) {
    height: 100%;
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 14px;
    line-height: 130%;
    transition: color 0.3s ease;
  }

  &__text {
    font-size: 14px;
    color: #6a6a6a;
    line-height: 150%;
    transition: color 0.3s ease;
  }

  &__hover {
    background-color: $primary;

    .build-type__title,
    .build-type__text {
      color: #fff;
    }
  }
}
</style>
