<template>
  <div class="start-step">
    <div class="container">
      <div class="section-header">
        <h2 class="section-header__title">
          How much <span>work experience</span> do you have?
        </h2>

        <p class="section-header__text">
          We can give you better advice and guidance if we know.
        </p>
      </div>

      <div class="experience">
        <div
          v-for="experience of workExperienceOptions"
          :key="experience.id"
          class="experience-item"
          :class="{
            'experience-item--active':
              activeExperience && activeExperience.id === experience.id,
          }"
          @click.prevent="setActiveExperience(experience)"
        >
          <h2 class="experience-item__count">{{ experience.count }}</h2>
          <p class="experience-item__text">Years</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash/lang";

export default {
  name: "StepInitExperience",

  data() {
    return {
      workExperienceOptions: [
        {
          id: this.$uuid.v4(),
          count: "0-3",
          text: `Typically <strong>0-3 years</strong> experience <br/>
          Welcome! We’ll make this easy and point you in the right direction.`,
        },
        {
          id: this.$uuid.v4(),
          count: "3-10",
          text: `Typically <strong>3-10 years</strong> experience <br/>
Got it! We’ll help take your resume to the next level.`,
        },
        {
          id: this.$uuid.v4(),
          count: "10+",
          text: `Typically <strong>10+ years</strong> experience <br/>
Great! We’ll help you frame your experience to show off your expertise.`,
        },
      ],
      activeExperience: null,
    };
  },

  methods: {
    setActiveExperience(experience) {
      this.activeExperience = cloneDeep(experience);
      const stepData = {
        key: "work_experience",
        value: this.activeExperience.count,
      };

      this.$emit("submit", stepData);
    },
    mounted() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.experience {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media (min-width: 320px) {
    flex-wrap: nowrap;
  }
  &-item {
    background-color: #fff;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 28%;
    max-width: 300px;
    margin: 0 8px;
    height: 95px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 16px 16px 22px 16px;
    cursor: pointer;
    transition: all 0.3s ease;

    @media (min-width: map_get($grid-breakpoints, lg)) {
      width: 25%;
    }

    @media (max-width: 347px) {
      width: 31%;
      margin-top: 20px;
    }

    &:hover {
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);

      background-color: $primary;

      .experience-item__count,
      .experience-item__text {
        color: #fff;
      }
    }

    &--active {
      background-color: $primary;

      .experience-item__count,
      .experience-item__text {
        color: #fff;
      }
    }

    &__count {
      font-weight: 600;
      font-size: 24px;
      line-height: 130%;
      transition: color 0.3s ease;
      @media (min-width: 320px) {
        font-size: 18px;
      }
    }

    &__text {
      font-size: 14px;
      line-height: 130%;
      color: $light-gray;
      margin-top: 8px;
      transition: color 0.3s ease;
    }
  }
}

.experience-description {
  margin-top: 50px;
  color: $primary;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  background: #f5f8ff;
  border-radius: 16px;
  padding: 24px;
  display: inline-block;
  margin-bottom: 24px;
}
.step-btn-wrap {
  @media (max-width: 320px) {
    margin-top: 25px !important;
  }
}
</style>
