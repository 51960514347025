<template>
  <div class="start-step">
    <div class="container start-step__container">
      <div class="section-header">
        <h1 class="section-header__title">
          <span>Hi there!</span>
          <br />
          Let's start with a few questions
        </h1>

        <p class="section-header__text">
          This will help us to use more specific recommendation to you
        </p>
      </div>

      <div class="step-btn-wrap">
        <AppButton
          title="Start"
          className="primary"
          iconName="TriangleIcon"
          @click.prevent="$emit('next')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepInitStart",

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.start-step__container {
  @media (max-width: map_get($grid-breakpoints, lg)) {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;

    .section-header {
      flex: 1;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
