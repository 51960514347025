import AppToast from "@/helpers/toast-methods";

const includedTypes = [
  "png",
  "jpg",
  "jpeg",
  "jpeg 2000",
  "jp2",
  "bmp",
  "png",
  "webp",
];

/**
 * Simple check image format
 * @param file
 * @param types {String[]}
 * @returns {boolean}
 */
export const extensionValidation = function (file, types) {
  const splattedFileName = file.name.split(".");
  const fileExtension = splattedFileName[splattedFileName.length - 1];

  return types.includes(fileExtension.toLowerCase());
};

export function simpleFileValidation(file, types = null) {
  if (!file) {
    AppToast.toastError("File not found");
    return false;
  }

  if (!file.type) {
    AppToast.toastError("File type missing");
    return false;
  }

  const validationTypes = types || includedTypes;

  if (!extensionValidation(file, validationTypes)) {
    AppToast.toastError(
      `${file.name}:available formats ${validationTypes.join(",")}`
    );
    return false;
  }

  if (file.size > 10097152) {
    AppToast.toastError(`${file.name} is too big`);
    return false;
  }

  return true;
}
