<template>
  <section class="initial-step">
    <transition name="fade" mode="out-in">
      <component
        :is="currentStep"
        @next="nextStep"
        @prev="prevStep"
        @submit="setResumeInitData"
        @finalize="createResume"
        @openUploadModal="openUploadModal"
        :isResumeLoader="true"
      />
    </transition>

    <AppModal
      :isOpenModal="isUploadModalOpened"
      width="600px"
      class="upload-resume-modal"
      @close="closeUploadModal"
    >
      <UploadResumeModal @uploadResume="uploadResume" />
    </AppModal>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import StepInitStart from "./components/StepInitStart";
import StepInitBuild from "./components/StepInitBuild";
import StepInitCountry from "./components/StepInitCountry";
import StepInitExperience from "./components/StepInitExperience";
import StepInitLoader from "./components/StepInitLoader";
import UploadResumeModal from "@/components/creation/UploadResumeModal";
import AppToast from "@/helpers/toast-methods";
import UncompletedCreationStep from "@/helpers/uncompleted-creation-step";
import { CREATING_RESUME_NAMES } from "@/helpers/rout-constants";
import CompletedTemplate from "@/helpers/completed-template-hash";
import CookieHelper from "@/helpers/cookie-helper";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";

const STEP_INIT_STEPS = [
  {
    name: "StepInitStart",
  },
  {
    name: "StepInitBuild",
  },
  {
    name: "StepInitCountry",
  },
  {
    name: "StepInitExperience",
  },
  {
    name: "StepInitLoader",
  },
];

export default {
  name: "StepInitialResumeCreation",
  components: {
    StepInitStart,
    StepInitBuild,
    StepInitCountry,
    StepInitExperience,
    StepInitLoader,
    UploadResumeModal,
  },
  mixins: [mixpanelMixin],

  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      user: "user/user",
    }),
  },

  data() {
    return {
      STEP_INIT_STEPS,
      currentStep: STEP_INIT_STEPS[0].name,
      resume: {
        template_id: 2,
      },
      isUploadModalOpened: false,
    };
  },

  methods: {
    nextStep() {
      this.mixpanelTrack(
        "V2-INITIAL-" + STEP_INIT_STEPS[this.getCurrentStep() + 1].name
      );

      this.currentStep = STEP_INIT_STEPS[this.getCurrentStep() + 1].name;
    },

    prevStep() {
      this.currentStep = STEP_INIT_STEPS[this.getCurrentStep() - 1].name;
    },

    getCurrentStep() {
      return STEP_INIT_STEPS.findIndex(
        (stepName) => stepName.name === this.currentStep
      );
    },

    /**
     * Set resume data on init step
     * @param resumeData: { key: any}
     */
    setResumeInitData(resumeData) {
      if (!resumeData.key || !resumeData.value) {
        AppToast.toastError("Incorrect data!");

        return;
      }

      this.resume[resumeData.key] = resumeData.value;

      this.nextStep();
    },

    openUploadModal() {
      this.isUploadModalOpened = true;
    },

    closeUploadModal() {
      this.isUploadModalOpened = false;
    },

    async uploadResume(file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("template_id", this.resume.template_id);

      await this.$store.dispatch("loader/pending");
      try {
        await this.$store.dispatch(
          "resumeCreation/uploadUsersResume",
          formData
        );
        UncompletedCreationStep.set(CREATING_RESUME_NAMES.STEP_1);

        await this.$router.push({ name: CREATING_RESUME_NAMES.STEP_1 });
      } catch (e) {
        console.error(e);
      }

      this.closeUploadModal();
      await this.$store.dispatch("loader/done");
    },

    async createResume() {
      try {
        let resume = { ...this.resume };

        if (this.user) {
          Object.assign(resume, this.user);
          delete this.resume.password_exists;
        }

        await this.$store.dispatch("resumeCreation/createResume", resume);

        CompletedTemplate.clear();
        UncompletedCreationStep.set(CREATING_RESUME_NAMES.STEP_1);

        await this.$router.push({ name: CREATING_RESUME_NAMES.STEP_1 });
      } catch (e) {
        this.currentStep = STEP_INIT_STEPS[0].name;
        console.error(e);
      }
    },
  },

  async created() {
    let vm = this;
    if (this.$route.params?.templateId === undefined) {
      this.$router.push("/");

      return;
    }

    this.resume.template_id = this.$route.params.templateId;

    this.mixpanelTrack("V2-INITIAL");
  },
};
</script>

<style lang="scss" scoped>
section.initial-step {
  display: flex;
  flex-direction: column;
  padding-top: 56px;
  overflow: visible;

  @media (min-width: map_get($grid-breakpoints, md)) {
    flex: 1;
  }

  @media (min-width: map_get($grid-breakpoints, lg)) {
    padding-top: 160px;
  }
}

::v-deep .section-header {
  margin-bottom: 24px;
  text-align: center;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    margin-bottom: 40px;
  }

  &__title {
    font-size: 32px;

    @media (min-width: map_get($grid-breakpoints, lg)) {
      font-size: 40px;
    }

    span {
      color: $primary;
    }
  }

  &__text {
    font-size: 16px;
    color: $light-gray;
    margin-top: 16px;

    @media (min-width: map_get($grid-breakpoints, lg)) {
      font-size: 18px;
    }
  }
}

::v-deep .start-step {
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    padding-bottom: 80px;
  }
}

::v-deep .step-btn-wrap {
  margin-top: 50px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: map_get($grid-breakpoints, md)) {
    margin-top: auto;
  }

  @media (min-width: map_get($grid-breakpoints, lg)) {
    padding-top: 30px;
  }

  .btn {
    @media (max-width: map_get($grid-breakpoints, lg)) {
      width: 100%;
    }
  }

  .back-btn {
    padding: 20px;
    margin-top: 20px;
    color: $body-color;
    font-size: 14px;

    &:hover {
      color: $primary;
    }
  }
}

::v-deep .modal {
  @media (max-width: map_get($grid-breakpoints, md)) {
    padding: 30px 20px;
  }
}
</style>
