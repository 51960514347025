<template>
  <button
    type="button"
    class="upload-service-btn"
    :disabled="!isLoaded"
    @click.prevent="$emit('click')"
  >
    <slot />

    <transition name="fade">
      <div v-if="!isLoaded" class="upload-service-btn__loader">
        <AppEmbedLoader />
      </div>
    </transition>
  </button>
</template>

<script>
import AppEmbedLoader from "@/components/ui/AppEmbedLoader";

export default {
  name: "UploadServiceButton",
  components: { AppEmbedLoader },

  props: {
    isLoaded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.upload-service-btn {
  padding: 10px 20px;
  height: 90px;
  margin-bottom: 16px;
  border: 1px solid $light-gray;
  border-radius: 8px;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 27px;
  position: relative;
  overflow: hidden;

  @media (min-width: map_get($grid-breakpoints, md)) {
    padding: 10px 40px;
    flex-direction: row;
    font-size: 18px;
    justify-content: flex-start;
  }

  ::v-deep svg {
    width: 32px;
    height: 32px;
    margin-bottom: 10px;

    @media (min-width: map_get($grid-breakpoints, md)) {
      margin-right: 32px;
      margin-bottom: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    border-color: $primary;
    color: $primary;
  }

  &:disabled {
    border-color: lighten($primary, 20%);
  }

  &__loader {
    background-color: lighten($primary, 20%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
