<template>
  <div class="start-step start-step--country">
    <div class="container">
      <div class="section-header">
        <h2 class="section-header__title">
          Where are you focusing your <span>job search</span>?
        </h2>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-6 col-lg-5 col-xl-4">
          <AppSelect
            v-if="jobCountries"
            label="Select country"
            :options="jobCountries"
            search
            @select="setCurrentCountry"
            @searchOptions="filterCountries"
            :initValue="defaultCountry"
          />
        </div>
      </div>
    </div>

    <div class="container text-center step-btn-wrap">
      <AppButton
        title="Next"
        className="primary"
        :disabled="!selectedCountry"
        @click.prevent="submitStep"
      />
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import CookieHelper from "@/helpers/cookie-helper";

export default {
  name: "StepInitCountry",

  data() {
    return {
      jobCountries: null,
      defaultCountriesList: null,
      selectedCountry: "",
      defaultCountry: "",
    };
  },

  methods: {
    setCurrentCountry(option) {
      CookieHelper.setCookie("countrycode", option.img, 30);
      CookieHelper.setCookie("country", option.content, 30);
      this.selectedCountry = option.content;
    },

    submitStep() {
      const stepData = {
        key: "country",
        value: this.selectedCountry,
      };

      this.$emit("submit", stepData);
    },

    filterCountries(searchString) {
      if (searchString.length < 2) {
        this.jobCountries = cloneDeep(this.defaultCountriesList);
        return;
      }

      const filteredCountries = this.defaultCountriesList.filter((country) => {
        const countryName = country.content.toLowerCase();

        const pattern = `^${searchString.toLowerCase()}`;
        const re = new RegExp(pattern);

        return re.test(countryName);
      });

      this.jobCountries = cloneDeep(filteredCountries);
    },

    createCountriesList(countriesJson) {
      this.defaultCountriesList = countriesJson.map((country) => {
        return {
          img: country.alpha2,
          content: country.name,
        };
      });
    },
  },

  created() {
    const countriesJson = require("@/helpers/countries.json");
    this.createCountriesList(countriesJson);

    this.jobCountries = cloneDeep(this.defaultCountriesList);

    let country = CookieHelper.getFlag("country", "");
    let countrycode = CookieHelper.getFlag("countrycode", "");
    if (country != "") {
      let CurrentCountry = { content: country, img: countrycode };
      this.setCurrentCountry(CurrentCountry);
      this.defaultCountry = country;
    }
  },
  mounted() {
    document.body.scrollTop = 0; // for safari
    document.documentElement.scrollTop = 0; // for chrome, firefox, IE
  },
};
</script>

<style lang="scss" scoped>
.start-step {
  padding-bottom: 50px;
}
.build-type-wrap {
  margin-top: 40px;
}

.build-type {
  padding: 32px;
  background-color: #fff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &__title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 14px;
    line-height: 130%;
    transition: color 0.3s ease;
  }

  &__text {
    font-size: 14px;
    color: #6a6a6a;
    line-height: 150%;
    transition: color 0.3s ease;
  }

  &:hover {
    background-color: $primary;

    .build-type__title,
    .build-type__text {
      color: #fff;
    }
  }
}
</style>
