<template>
  <div class="upload-resume">
    <h3 class="upload-resume__title">Upload resume</h3>

    <UploadServiceButton
      :isLoaded="isGoogleDriveLoad"
      @click="triggerUploadGoogle"
    >
      <AppIcon componentName="GoogleDrive" />
      Upload from Google Drive
    </UploadServiceButton>

    <UploadServiceButton
      :isLoaded="isDropBoxLoad"
      @click="triggerUploadDropbox"
    >
      <AppIcon componentName="DropBox" />
      Upload from Dropbox
    </UploadServiceButton>

    <UploadServiceButton isLoaded @click="triggerUploadPC">
      <AppIcon componentName="PC" />
      Upload from your computer
    </UploadServiceButton>

    <input
      ref="uploadPCField"
      type="file"
      class="field-pc-upload"
      @change="uploadPC"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";

import UploadServiceButton from "@/components/creation/UploadServiceButton";
import { extensionValidation } from "@/helpers/extansions-validations";
import AppToast from "@/helpers/toast-methods";

const ALLOW_FILES_EXTENSION = ["pdf", "doc", "docx"];

export default {
  name: "UploadResumeModal",
  components: { UploadServiceButton },

  computed: {
    ...mapGetters({
      isDropBoxLoad: "fileServices/isDropBoxLoad",
      isGoogleDriveLoad: "fileServices/isGoogleDriveLoad",
    }),
  },

  data() {
    return {
      dropboxOptions: {
        success: this.uploadDropbox,
        cancel: () => {},
        linkType: "direct",
        extensions: [".pdf", ".doc", ".docx"],
        // folderselect: false,
        // sizeLimit: 102400000,
      },

      pickerApiLoaded: false,
      developerKey: process.env.VUE_APP_GOOGLE_API_KEY,
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      scope: "https://www.googleapis.com/auth/drive",
      oAuthToken: null,
    };
  },

  methods: {
    triggerUploadPC() {
      this.$refs.uploadPCField.value = "";
      this.$refs.uploadPCField.click();
    },

    triggerUploadDropbox() {
      // eslint-disable-next-line
      Dropbox.choose(this.dropboxOptions);
    },

    async triggerUploadGoogle() {
      try {
        await window.gapi.load("client:auth2", () => {
          gapi.auth2.authorize(
            {
              client_id: this.clientId,
              scope: this.scope,
              immediate: false,
            },
            this.handleAuthResult
          );
        });

        await gapi.load("picker", () => {
          this.pickerApiLoaded = true;
          this.createPicker();
        });
      } catch (e) {
        console.error(e);
      }
    },

    uploadPC() {
      if (!this.$refs.uploadPCField.files.length) {
        AppToast.toastError("No uploaded files!");
        this.$refs.uploadPCField.value = "";

        return;
      }

      if (
        !extensionValidation(
          this.$refs.uploadPCField.files[0],
          ALLOW_FILES_EXTENSION
        )
      ) {
        AppToast.toastError(
          `Invalid file extension. Allowed extensions: ${ALLOW_FILES_EXTENSION.join(
            ","
          )}`
        );

        this.$refs.uploadPCField.value = "";

        return;
      }

      this.$emit("uploadResume", this.$refs.uploadPCField.files[0]);
    },

    /**
     * Download files from Dropbox flow
     * @param files
     * @returns {Promise<void>}
     */
    async uploadDropbox(files) {
      const fileInfo = files[0];
      const fileArr = fileInfo.name.split(".");
      const fileType = fileArr[fileArr.length - 1];

      try {
        const file = await fetch(fileInfo.link);
        const blob = await file.blob();

        const resumeFile = new File([blob], fileInfo.name, {
          type: fileType,
        });

        this.$emit("uploadResume", resumeFile);
      } catch (e) {
        console.error(e, "Error upload from dropbox");
        AppToast.toastError("Sorry, we were unable to upload the file.");
      }
    },

    /**
     * Google auth
     * @param authResult
     */
    handleAuthResult(authResult) {
      if (authResult && !authResult.error) {
        this.oAuthToken = authResult.access_token;
        this.createPicker();

        return;
      }
      AppToast.toastError(authResult.details);
    },

    /**
     * Creation google drive file picker window
     */
    createPicker() {
      if (this.pickerApiLoaded && this.oAuthToken) {
        let picker = new google.picker.PickerBuilder()

          .addView(google.picker.ViewId.DOCS)
          .setOAuthToken(this.oAuthToken)
          .setDeveloperKey(process.env.VUE_APP_GOOGLE_API_KEY)
          .setCallback(this.pickerCallback)
          .build();
        picker.setVisible(true);

        return;
      }

      console.error(
        "Error creation picker",
        `Picker loaded: ${this.pickerApiLoaded}`
      );
    },

    async pickerCallback(details) {
      if (details.action === "picked") {
        try {
          const fileDataResponse = await fetch(
            `https://www.googleapis.com/drive/v3/files/${details.docs[0].id}?fields=name,mimeType,id`,
            {
              headers: {
                authorization: "Bearer " + this.oAuthToken,
              },
            }
          );
          const fileData = await fileDataResponse.json();

          const fileBinary = await fetch(
            `https://www.googleapis.com/drive/v3/files/${details.docs[0].id}?alt=media`,
            {
              headers: {
                authorization: "Bearer " + this.oAuthToken,
              },
            }
          );

          const fileBlob = await fileBinary.blob();
          const resumeFile = new File([fileBlob], fileData.name, {
            type: fileData.mimeType,
          });

          this.$emit("uploadResume", resumeFile);
        } catch (e) {
          console.error(e);

          AppToast.toastError("Sorry, we were unable to upload the file.");
        }
      }
    },
  },

  mounted() {
    this.$store.dispatch("fileServices/generateDropboxScript");

    this.$store.dispatch("fileServices/generateGoogleDriveScript");
  },
};
</script>

<style lang="scss" scoped>
.upload-resume {
  width: 100%;

  &__title {
    margin-bottom: 30px;
  }

  .field-pc-upload {
    display: none;
  }
}
</style>
